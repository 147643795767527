:root {
  --spacing: 2.5rem;
}

@font-face {
  font-family: univia-pro;
  src: url("univia-pro-book.03045238.woff2") format("woff2"), url("univia-pro-book.bc96d761.woff") format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: univia-pro;
  src: url("univia-pro-light.c8bb8e25.woff2") format("woff2"), url("univia-pro-light.2f7c76a6.woff") format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html {
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  -webkit-font-smoothing: antialiased;
  color: #000;
  background-color: #fff;
  font-family: univia-pro, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem;
  position: relative;
}

img {
  max-width: 100%;
  display: block;
}

p {
  margin-bottom: 1rem;
}

a {
  color: inherit;
  text-underline-offset: 2px;
  font-weight: 500;
  text-decoration-thickness: 1px;
  transition: all .3s ease-in;
}

a:hover, a:focus-visible {
  text-decoration: none;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.column--melrose {
  color: #fff;
  background: linear-gradient(#3f6695 0%, #14202f 100%);
}

.company {
  flex-direction: column;
  max-width: 32rem;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
}

h2 {
  margin: var(--spacing) 0;
  align-items: flex-end;
  height: 6.25rem;
  display: flex;
}

.companies {
  padding: var(--spacing) 0;
  list-style-type: none;
}

.company-info {
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  display: flex;
}

.company-info h3 {
  width: 201px;
}

.company-info img {
  margin: 0 auto;
}

.company-info p {
  margin: 0;
  font-size: .75rem;
}

.img-automotive, .img-pm {
  height: 50px;
}

@media (width <= 500px) {
  .company-info {
    flex-direction: column;
    justify-content: center;
  }

  .company-info h3 {
    padding-bottom: 1rem;
  }
}

@media (width >= 1024px) {
  :root {
    --spacing: 7.5rem;
  }

  body {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  body:before {
    content: "";
    z-index: -1;
    background: linear-gradient(#3f6695 0%, #14202f 100%);
    width: 50%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }

  main {
    height: 100vh;
    display: flex;
  }

  .column {
    width: 50%;
  }

  .column--melrose {
    background: none;
  }
}
/*# sourceMappingURL=index.58ac71bd.css.map */
