:root {
  --spacing: 2.5rem; // 40px
}

@font-face {
  font-family: "univia-pro";
  src: url("../fonts/univia-pro-book.woff2") format("woff2"), url("../fonts/univia-pro-book.woff") format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "univia-pro";
  src: url("../fonts/univia-pro-light.woff2") format("woff2"), url("../fonts/univia-pro-light.woff") format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: white;
  color: #000;
  font-family: "univia-pro", sans-serif;
  
  font-style: normal;
  font-weight: 300;
  font-size: 1rem; // 16px
  line-height: 1.5rem; // 24px
  position: relative;
}

img {
  display: block;
  max-width: 100%;
}

p {
  margin-bottom: 1rem;
}

a {
  color: inherit;
  font-weight: 500;
  transition: all .3s ease-in;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}


// layout
.column--melrose {
  background: linear-gradient(180deg, #3F6695 0%, #14202F 100%);
  color: white;
}

.company {
  max-width: 32rem; /* 512px */
  padding: 0 1rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

h2 {
  display: flex;
  height: 6.25rem; // 100px
  align-items: flex-end;
  margin: var(--spacing) 0; 
}

.companies {
  list-style-type: none;
  padding: var(--spacing) 0 ; 
}

.company-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;

  h3 {
    width: 201px;
  }

  img {
    margin: 0 auto;
  }

  p {
    margin: 0;
    font-size: .75rem; // 12px
  }
}

.img-automotive {
  height: 50px;
}
.img-pm {
  height: 50px;
}

@media (max-width: 500px) {
  .company-info {
    flex-direction: column;
    justify-content: center;

    h3 {
      padding-bottom: 1rem;
    }
  }
}

@media (min-width: 1024px) {
  :root {
    --spacing: 7.5rem; // 120px
  }
  body {
    font-size: 1.25rem; // 20px
    line-height: 1.75rem; // 28px

    &:before {
      content: '';
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      width: 50%;
      background: linear-gradient(180deg, #3F6695 0%, #14202F 100%);
      z-index: -1;    
    }
  }

  main {
    display: flex;
    height: 100vh;
  }

  .column {
    width: 50%;
  }

  .column--melrose {
    background: none;
  }
}